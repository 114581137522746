'use strict';

// Import Libraries
import React from 'react';
import classNames from 'classnames';
// Import JSX Modules
import NUp from './n-up';

// -----------------------------------------------------------
//  NUps
// -----------------------------------------------------------

class NUps extends React.Component {
  componentDidMount() {
    $('.n-ups > div').slick({
      // generic settings
      arrows: false,
      infinite: true,
      speed: 300,
      dots: true,


      // xl - wide desktops
      slidesToShow: 10,
      slidesToScroll: 10,

      responsive: [{
        // lg - desktops
        breakpoint: 1200,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8
        }
      }, {
        // md - tablets
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }, {
        // sm - new phones
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }, {
        // xs - old phones
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }]
    });
  }

  render() {
    const { collection } = this.props;
    return (
      <div className={classNames("n-ups", collection.length < 6 ? 'text-center' : 'text-left')}>
        <div>
          {
            (collection || []).map((item, i) => (
              <NUp
                data={item}
                key={i}
                onUrlFiltersChange={this.props.onUrlFiltersChange} />
            ))
          }
        </div>
      </div>
    );
  }
};

export default NUps;
