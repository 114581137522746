// -----------------------------------------------------------
//  UrlQueryParser
// -----------------------------------------------------------
// Why yes, this was written in CoffeeScript!
const UrlQueryParser = (function () {
  let decodeValue;

  function UrlQueryParser(url) {
    let i, len, ref, ref1, pair;

    if (url == null && typeof location !== 'undefined') {
      url = decodeURI(location.href);
    }
    this.hashParts = {};
    ref1 = ((ref = url.split('?')[1]) != null ? ref.split('&') : void 0) || [];
    for (i = 0, len = ref1.length; i < len; i++) {
      if (pair = ref1[i]) {
        pair = pair.split('=');
        this.hashParts[decodeURIComponent(pair[0])] = pair[1] || '';
      }
    }
  }

  UrlQueryParser.prototype.filters = function () {
    const result = {};
    const ref = this.hashParts;

    for (let key in ref) {
      if (!(key.indexOf('_') !== 0 && key !== 'delivery-options' && key !== 'search')) {
        continue;
      }
      result[key] = decodeValue(ref[key]);
    }

    return result;
  };

  UrlQueryParser.prototype.search = function () {
    const ref = this.hashParts;

    for (let key in ref) {
      if (key === 'search') {
        return decodeValue(ref[key])[0];
      }
    }
  };

  UrlQueryParser.prototype.delivery = function () {
    const ref = this.hashParts;

    for (let key in ref) {
      if (key === 'delivery-options') {
        return decodeValue(ref[key], true);
      }
    }
  };

  UrlQueryParser.prototype.startState = function () {
    const result = {};
    const ref = this.hashParts;

    for (let key in ref) {
      if (!(key.indexOf('_') === 0)) {
        continue;
      }
      key = key.substring(1);
      result[key] = decodeValue(ref[key], key === 'page')[0];
    }

    return result;
  };

  decodeValue = function (value, shouldParseInt) {
    const ref = value.split('|');
    const results = [];

    for (let i = 0, len = ref.length; i < len; i++) {
      const s = ref[i];
      const value = decodeURIComponent(s);
      results.push(shouldParseInt ? parseInt(value, 10) : value);
    }

    return results;
  };

  return UrlQueryParser;
}());

export default UrlQueryParser;
