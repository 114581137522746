import React from 'react';
import UrlQueryParser from './url-query-parser';

const NUp = props => {
  return (
    <a
      className="n-up"
      href={props.data.url}
      role="link"
      onClick={handleClick}
    >
      <img
        alt=""
        role="presentation"
        src={props.data.image}
        className="img-fluid"
      />
      <div className="title text-center">{props.data.title}</div>
    </a>
  );

  function handleClick(e) {
    var a = document.createElement('a');
    a.href = props.data.url;

    if (a.pathname === location.pathname && a.search) {
      // link is to the current page
      e.preventDefault();

      var urlQueryParser = new UrlQueryParser(a.href);
      props.onUrlFiltersChange(urlQueryParser.filters());
    }
  }
}
export default NUp;